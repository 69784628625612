import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import loginImage from "../../../assets/images/loginImage.png";
// import imslogo from "../../../assets/icons/ims-logo.svg";
// import maskImage from "../../../assets/images/maskImage.png";
import { setCurrentHeaderData } from "../../../redux/features/header/headerSlice";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SwiperCore, { Navigation, Autoplay, Pagination, Virtual } from "swiper";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";
import "swiper/swiper-bundle.min.css";
import "./swiper.css";
SwiperCore.use([Navigation, Autoplay, Pagination, Virtual]);
import { useQuery } from "react-query";
import { endpoint } from "../../../API/config";
import { adminLoginAPI_apirequest } from "../../../API/api";
import {
  changeCourse,
  saveLoginDetails,
  setChannelId,
} from "../../../redux/features/login/LoginSlice";
import Loader from "../global/loader/Loader";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import {
  DOMAINS,
  LOGIN_PARAS,
  NAME,
  SUPPORT_LOGIN,
  HEADER_LOGOS,
} from "../../../DomainConfig";

// const swiperData = [
//   {
//     id: 1,
//     title: "Experience in shaping success stories",
//     para: "IMS’s experienced mentorship aids students in achieving high scores best suited to their objectives, aspirations, and skill sets.",
//   },
//   {
//     id: 2,
//     title: "We believe every student's journey is unique",
//     para: "IMS creates tailor-made learning paths by recognizing their unique traits and capabilities.",
//   },
//   {
//     id: 3,
//     title: "There is limitless possibility",
//     para: "IMS ensures that its students are not just exam-ready but also to embark on their next academic journey.",
//   },
// ];
const currentDomain = window.location.hostname;

const swiperData = [
  {
    id: 1,
    title: "Experience in shaping success stories",
    // para: "IMS’s experienced mentorship aids students in achieving high scores best suited to their objectives, aspirations, and skill sets.",
    para:
      currentDomain === DOMAINS.MANYA_GROUP
        ? LOGIN_PARAS.PARA1.MANYA_GROUP
        : LOGIN_PARAS.PARA1.IMS_INDIA,
  },
  {
    id: 2,
    title: "We believe every student's journey is unique",
    // para: "IMS creates tailor-made learning paths by recognizing their unique traits and capabilities.",
    para:
      currentDomain === DOMAINS.MANYA_GROUP
        ? LOGIN_PARAS.PARA2.MANYA_GROUP
        : LOGIN_PARAS.PARA2.IMS_INDIA,
  },
  {
    id: 3,
    title: "There is limitless possibility",
    // para: "IMS ensures that its students are not just exam-ready but also to embark on their next academic journey.",
    para:
      currentDomain === DOMAINS.MANYA_GROUP
        ? LOGIN_PARAS.PARA3.MANYA_GROUP
        : LOGIN_PARAS.PARA3.IMS_INDIA,
  },
];

const Login = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [isLoginClicked, setIsLoginClicked] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isLoginError, setIsLoginError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [studentUserName, setStudentUserName] = useState("");
  false;
  const [errorMsg, setErrorMsg] = useState("");
  let signUpRedirectionURL =
    "https://www.imsindia.com/zero-fee-enrollment/?degree=&txtlimacampaign=&utm_source=&utm_medium=&utm_campaign=&utm_term=&utm_content";
  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };
  const getLoginDetails = (userName, password, studentUserName) => {
    return adminLoginAPI_apirequest({
      url: `${endpoint.adminLogin.addr}`,
      method: "post",
      data: {
        adminImsPin: userName?.trim(),
        adminPassword: password,
        studentImsPin: studentUserName?.trim(),
        platform: "support",
      },
    });
  };

  const { isLoading: isLoginLoading } = useQuery(
    [endpoint.adminLogin.key],
    () => getLoginDetails(userName, password, studentUserName),
    {
      retry: false,
      enabled: !!isLoginClicked,
      onSuccess: (res) => {
        setShowLoader(false);
        let data = res.data.data;
        dispatch(saveLoginDetails(data));
        // dispatching the channelId after login
        dispatch(setChannelId(data?.channelData?.moduleId));
        let courseIdNVariantId =
          data.lastLoggedInCourseId.toString() +
          "_" +
          data.lastLoggedInVariantId.toString();
        let courseId = data.lastLoggedInCourseId.toString();
        let name = data.lastloggedInCourseName;
        let variantId = data.lastLoggedInVariantId;
        dispatch(
          changeCourse({ courseIdNVariantId, courseId, name, variantId })
        );
        // TODO! Replace Local storage with cookies
        let loginInfo = {
          adminemail: res.data.data.studentEmail
            ? res.data.data.studentEmail
            : "",
          adminimspin: res.data.data.student_imspin
            ? res.data.data.student_imspin
            : "",
          txtpassword: password,
        };
        // Sending Cookie to CMS
        const EncryptedLoginInfo = CryptoJS.AES.encrypt(
          JSON.stringify(loginInfo),
          "secret key 123"
        ).toString();
        Cookies.set("loginInfo", EncryptedLoginInfo, {
          domain: ".imsindia.com",
          secure: true,
        });
        localStorage.setItem("loginInfo", EncryptedLoginInfo);
        dispatch(setCurrentHeaderData(res.data.data.headerModuleData));
        //TODO  TO BE REMOVED AFTER  OTP MODAL IS LINKED
        history.push("/prelogin");
      },
      onError: (err) => {
        setErrorMsg(err?.data?.message);
        setIsLoginError(true);
        setIsLoginClicked(false);
        setShowLoader(false);
      },
    }
  );
  const handleClick = (e) => {
    e.preventDefault();
    setIsLoginClicked(true);
    setShowLoader(true);
  };

  const handleChangeUserName = (e) => {
    setUserName(e.target.value);
  };
  const handleChangeStudentUserName = (e) => {
    setStudentUserName(e.target.value);
  };

  return (
    <div
      className="grid font-ibm w-100% h-screen"
      style={{
        gridTemplateColumns: "60% 1fr",
      }}
    >
      <div className=" max-h-screen">
        <img
          className="w-[100%] h-[100%] object-cover"
          src={loginImage}
          alt="login-image"
          loading="lazy"
        />
        <div className="max-w-[60%] hidden md:block absolute bottom-5 left-24">
          <div className="h-auto space-y-5 gap-5">
            <button
              className="bg-white text-black text-base font-medium px-8 py-2 rounded-xl ml-10"
              onClick={() =>
                window.open(signUpRedirectionURL, "_blank", "noreferrer")
              }
            >
              Sign Up
            </button>

            <section className="max-w-[350px] ml-10">
              <Swiper
                slidesPerView={1}
                pagination={true}
                autoplay
                virtual
                itemType="fractionx"
              >
                {swiperData.map((item) => (
                  <SwiperSlide key={item.id} className="space-y-5  mb-8">
                    <h1 className="text-3.5xl text-white  font-extralight">
                      {item.title}
                    </h1>
                    <p className="text-lg text-white font-light">{item.para}</p>
                  </SwiperSlide>
                ))}
              </Swiper>
            </section>
          </div>
        </div>
      </div>
      <section className=" h-auto">
        {/* <div className="w-[100%]"> */}
        <div className="grid grid-cols-1 place-content-center justify-items-center py-5">
          <div className="justify-start w-[80%]">
            <img
              className="w-20 h-12 -ml-3"
              src={
                currentDomain === DOMAINS.MANYA_GROUP
                  ? HEADER_LOGOS.MANYA_GROUP_LOGO
                  : HEADER_LOGOS.LOGO
              }
              alt="ims-logo"
              loading="lazy"
            />
            <div className="space-y-5 font-ibm grid grid-cols-1 justify-center align-middle py-12 mt-4">
              <div>
                <span className="font-semibold text-4xl">Login to </span>
                <span className="font-semibold text-4xl text-primary-200">
                  {currentDomain === DOMAINS.MANYA_GROUP
                    ? NAME.MANYA_GROUP
                    : NAME.IMS_INDIA}
                </span>
              </div>
              <div>
                <span className="text-xl">
                  {currentDomain === DOMAINS.MANYA_GROUP
                    ? SUPPORT_LOGIN.TITLE.MANYA_GROUP
                    : SUPPORT_LOGIN.TITLE.IMS_INDIA}
                </span>
                <form
                  className="grid grid-cols-1 space-y-3"
                  onSubmit={handleClick}
                >
                  <input
                    value={userName}
                    onKeyDown={handleEnter}
                    onChange={(e) => handleChangeUserName(e)}
                    type="text"
                    className="border-2 border-gray-200 rounded-2xl py-3 px-3 focus:outline-none mt-5"
                    placeholder="Admin Username"
                  />
                  <input
                    value={password}
                    onKeyDown={handleEnter}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    className="border-2 border-gray-200 rounded-2xl py-3 px-3 focus:outline-none"
                    placeholder="Admin Password"
                  />
                  <input
                    value={studentUserName}
                    onKeyDown={handleEnter}
                    onChange={(e) => handleChangeStudentUserName(e)}
                    type="text"
                    className="border-2 border-gray-200 rounded-2xl py-3 px-3 focus:outline-none mt-5"
                    placeholder="Student Username"
                  />
                  <div className="flex justify-between">
                    {/* {!isParent && <span className="underline text-base font-medium mt-4 cursor-pointer" onClick={()=>setIsPasswordForgotten(true)}>
                    Forgot password
                    </span>} */}

                    <div className="flex flex-col ">
                      {showLoader ? (
                        <Loader />
                      ) : (
                        <button
                          disabled={
                            userName && password && studentUserName != ""
                              ? false
                              : true
                          }
                          onClick={handleClick}
                          onFocus={handleClick}
                          className={`w-full px-16 xl:px-24 py-3 mt-2 rounded-xl text-white text-lg font-medium ${
                            userName && password && studentUserName != ""
                              ? "bg-primary-200"
                              : "bg-[#8E9EAB]"
                          } `}
                        >
                          Login
                        </button>
                      )}
                      {isLoginError && !isLoginLoading && (
                        <p className="text-red-500 text-base font-medium mt-2">
                          {errorMsg ? errorMsg : "Something Went Wrong"}
                        </p>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Login;
