import propTypes from "prop-types";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { loginAPI_apirequest } from "../../../API/api";
import { endpoint } from "../../../API/config.jsx";
import {
  hoverOnHeader,
  selectShowSecondSubheader,
  selectShowSubheader,
  setCurrentHeaderData,
  setCurrentHeaderTab,
} from "../../../redux/features/header/headerSlice";
import {
  setStudentPageData,
  setStudentPageTab,
} from "../../../redux/features/login/LoginSlice";

import SubheaderContainer from "../header/SubheaderContainer";
import { DOMAINS, HEADER_LOGOS } from "../../../DomainConfig.js";

const StudentPageHeader = () => {
  const dispatch = useDispatch();
  const paramsQuery = useParamsQuery();

  const selectedHeaderTab = useSelector((state) => state.login.studentPageTab);
  const showSubheader = useSelector(selectShowSubheader);
  const showSecondSubheader = useSelector(selectShowSecondSubheader);
  const stdImsPin =
    paramsQuery.get("studentImsPin") || localStorage.getItem("studentImsPin");
  const variantId =
    paramsQuery.get("variantId") || localStorage.getItem("variantId");
  const courseId =
    paramsQuery.get("courseId") || localStorage.getItem("courseId");
  const courseName =
    paramsQuery.get("courseName") || localStorage.getItem("courseName");
  const history = useHistory();

  const handleHoverOnHeader = (headerObj) => {
    dispatch(setStudentPageTab(headerObj.name));

    dispatch(setStudentPageData(headerObj));
    // dispatch hover action only if the header tier has children.
    if (headerObj.isHeader !== "1") dispatch(hoverOnHeader(headerObj));
  };
  const handleClick = (headerObj) => {
    dispatch(setStudentPageTab(headerObj.name));

    dispatch(setStudentPageData(headerObj));
    if (headerObj.type.toLowerCase() == "performance") {
      dispatch(setCurrentHeaderTab(headerObj.name));
    }
    history.push(
      `/studentPage/?studentImsPin=${stdImsPin}&variantId=${variantId}&courseId=${courseId}&courseName=${courseName}`
    );
  };
  const getMentorStudentHeaderModule = () => {
    return loginAPI_apirequest({
      url: `${endpoint.GetMentorStudentHeaderModule.addr}`,
      method: "post",
      headers: {
        "access-token": "",
      },
      data: {
        studentImsPin: stdImsPin,
        variantId: variantId,
        courseId: courseId,
      },
    });
  };

  const { data: headerdata, refetch } = useQuery(
    [endpoint.GetMentorStudentHeaderModule.key],
    getMentorStudentHeaderModule,
    {
      onSuccess: (res) => {
        dispatch(setCurrentHeaderData(res.data.data));
      },
    }
  );

  function useParamsQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  useEffect(() => {
    refetch();
  }, []);

  const currentDomain = window.location.hostname;

  return (
    <div className=" font-ibm">
      <div
        className={`w-full bg-secondary-100  sticky z-10 shadow-outer mb-14 
        ${
          showSubheader || showSecondSubheader
            ? "border-b-[1px] border-[#E5E5E5]"
            : "shadow-outer"
        }`}
      >
        <header className="h-20 w-[95%] mx-auto  flex flex-row justify-between items-center ">
          {/* Left Side */}
          <img
            className="h-16 w-16 cursor-pointer"
            style={{
              width: currentDomain === DOMAINS.MANYA_GROUP ? "auto" : "64px",
            }}
            src={
              currentDomain === DOMAINS.MANYA_GROUP
                ? HEADER_LOGOS.MANYA_GROUP_LOGO
                : HEADER_LOGOS.LOGO
            }
            alt={
              currentDomain === DOMAINS.MANYA_GROUP
                ? "Manya Group Logo"
                : "My IMS Logo"
            }
            loading="lazy"
          />

          {/* Middle */}
          <div className="flex flex-1 ">
            {headerdata?.data.data?.map((option, index) => (
              <div
                key={index}
                onMouseEnter={() =>
                  option.type.toLowerCase() == "performance"
                    ? handleHoverOnHeader(option)
                    : ""
                }
                onClick={() => handleClick(option)}
                className={`ml-12 text-lg font-ibm capitalize cursor-pointer text-secondary-600 ${
                  selectedHeaderTab == option?.name ? "text-primary-200" : ""
                }   font-medium hover:text-primary-200`}
              >
                {option?.name}
              </div>
            ))}
          </div>
        </header>
        {selectedHeaderTab == "Performance" ? (
          <SubheaderContainer isFromStudentProfile={true} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
StudentPageHeader.propTypes = {
  studentImsPin: propTypes.string,
};
export default StudentPageHeader;
