import manyaGroupLogo from "./assets/icons/manya-logo.png";
import logo from "./assets/icons/ims-logo.svg";

export const DOMAINS = {
    MANYA_GROUP: "students.manyagroup.com",
    IMS_INDIA: "myimsv3.imsindia.com"
};


export const HEADER_LOGOS = {
    MANYA_GROUP_LOGO: manyaGroupLogo,
    LOGO: logo
};

export const SIGN_UP_DOMAIN = {
    MANYA_GROUP: "https://www.manyagroup.com/enquiry/test-prep-demo/demo-selection?utm_source=Website&utm_content=homepagebanner&utm_medium=homepage",
    IMS_INDIA: "https://www.imsindia.com/zero-fee-enrollment/?degree=&txtlimacampaign=&utm_source=&utm_medium=&utm_campaign=&utm_term=&utm_content"
};

export const ZERO_FEE_MESSAGE = {
    MANYA_GROUP: "book our free demo",
    IMS_INDIA: "enroll in our Zero-fee program"
};

export const LOGIN_PARAS = {
    PARA1: {
        IMS_INDIA: "IMS’s experienced mentorship aids students in achieving high scores best suited to their objectives, aspirations, and skill sets.",
        MANYA_GROUP: "Our experienced mentorship aids students in achieving high scores best suited to their objectives, aspirations, and skill sets."
    },
    PARA2: {
        IMS_INDIA: "IMS creates tailor-made learning paths by recognizing their unique traits and capabilities.",
        MANYA_GROUP: "We create tailor-made learning paths by recognizing their unique traits and capabilities."
    },
    PARA3: {
        IMS_INDIA: "IMS ensures that its students are not just exam-ready but also to embark on their next academic journey.",
        MANYA_GROUP: "We ensure that its students are not just exam-ready but also to embark on their next academic journey."
    }
};


export const NAME = {
    MANYA_GROUP: "Student Portal",
    IMS_INDIA: "myIMS"
};


export const LOGIN_PAGE = {
    HEADING: {
        IMS_INDIA: "Enter your registered Mobile Number / IMS Pin",
        MANYA_GROUP: "Enter your registered Mobile Number / Student Pin"
    },
    PLACEHOLDER: {
        IMS_INDIA: "Enter Mobile Number / IMS Pin",
        MANYA_GROUP: "Enter Mobile Number / Student Pin"
    },
    OTP_TITLE: {
        IMS_INDIA: "Authenticate via OTP to set up your myIMS Account",
        MANYA_GROUP: "Authenticate via OTP to set up your Student Account"
    },
    GET_OTP_LABEL: {
        IMS_INDIA: "Enter your registered Email ID or IMS Pin to receive an OTP",
        MANYA_GROUP: "Enter your registered Email ID or Student Pin to receive an OTP"
    },
    OTP_PLACEHOLDER: {
        IMS_INDIA: "Registered Email-Id/ IMS Pin",
        MANYA_GROUP: "Registered Email-Id/ Student Pin"
    },
    PARENT_OTP_TITLE: {
        IMS_INDIA: "Login to myIMS for Parents",
        MANYA_GROUP: "Login for Parents"
    }

};



export const SUPPORT_LOGIN = {
    TITLE: {
        IMS_INDIA: "Enter your registered Mobile Number / IMS Pin, Password & Student's IMS Pin / Email",
        MANYA_GROUP: "Enter your registered Mobile Number / Student Pin, Password & Student's Pin / Email"
    }
};


export const MOBILE_PAGE = {
    LOGO_ALT: {
        IMS_INDIA: "My IMS Logo",
        MANYA_GROUP: "Logo"
    },
    TITLE: {
        IMS_INDIA: "Experience MyIMS Better on Our App",
        MANYA_GROUP: "Experience the Portal Better on PC Browser"
    },
    DESCRIPTION: {
        IMS_INDIA: "For a smoother, faster, and more optimized experience, please download the MyIMS mobile app.Access all your featuers with ease",
        MANYA_GROUP: "For a smoother, faster, and more optimized experience, we recommend using PC browser."
    }
};

export const SUPPORT_LINK = {
    HYPERLINK: {
        IMS_INDIA: "https://support.imsindia.com/",
        MANYA_GROUP: "mailto:care@manyagroup.com"
    }
};