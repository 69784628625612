import Badge from "@material-ui/core/Badge";
import React from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { homeAPI_apirequest, loginAPI_apirequest } from "../../../API/api";
import { endpoint } from "../../../API/config";
import notification from "../../../assets/icons/notification.svg";
import {
  clearData,
  clearTimeout,
  hoverOnHeader,
  selectCurrentHeaderData,
  selectCurrentHeaderTab,
  selectShowSecondSubheader,
  selectShowSubheader,
  setCurrentHeaderTab,
  setHeaderStateWithUrl,
  setTimeoutID,
} from "../../../redux/features/header/headerSlice";
import { loginDetails } from "../../../redux/features/login/LoginSlice";
import ProfileDropDown from "../profile/ProfileDropdown";

import SubheaderContainer from "./SubheaderContainer";
import { dropdownData } from "./headerData";
import { DOMAINS, HEADER_LOGOS } from "../../../DomainConfig";

const Header = () => {
  const dispatch = useDispatch();
  const currentHeaderData = useSelector(selectCurrentHeaderData);
  const showSubheader = useSelector(selectShowSubheader);
  const showSecondSubheader = useSelector(selectShowSecondSubheader);
  const currentHeaderTab = useSelector(selectCurrentHeaderTab);
  const history = useHistory();
  const location = useLocation();
  const locationArr = location.pathname.split("/");
  const [notificationCount, setNotificationCount] = React.useState(0);
  const LoginDetails = useSelector(loginDetails);
  const courseID = useSelector((state) => state.login.courseId);
  const variantID = useSelector((state) => state.login.variantId);

  const handleHoverOnHeader = (headerObj) => {
    // dispatch hover action only if the header tier has children.
    if (headerObj.isHeader !== "1") dispatch(hoverOnHeader(headerObj));
  };

  // get student data api call
  const fectchStudentData = () => {
    return loginAPI_apirequest({
      url: `${endpoint.getStudentData.addr}`,
      method: "post",
      headers: {
        "access-token": LoginDetails.token,
      },
      data: {
        studentImsPin: LoginDetails.student_imspin,
      },
    });
  };

  const { data: getStudentData } = useQuery(
    `${endpoint.getStudentData.key}`,
    fectchStudentData,
    {
      select: (res) => res.data.data,
      onSuccess: (res) => {
        localStorage.setItem("centre", res.centre);
      },
    }
  );

  const handleClick = (headerObj) => {
    if (headerObj.isHeader === "1") {
      dispatch(clearData());
      if (headerObj.name.toLowerCase() === "home") history.push("/home");
      else if (headerObj.type.toLowerCase() === "hyperlink")
        window.open(headerObj.url);
      else history.push(`/${headerObj.type}`);
      dispatch(setCurrentHeaderTab(headerObj.name));
    }
    // if (headerObj.name === "Classes") {
    //   history.push("/home/classes");
    //   dispatch(setCurrentHeaderTab("Classes"));
    // }
    if (headerObj.name.toLowerCase() === "upgrades") {
      history.push("/upgrade");
      dispatch(setCurrentHeaderTab(headerObj.name));
    }
    return;
  };

  React.useEffect(() => {
    dispatch(setHeaderStateWithUrl(locationArr));
  }, [location]);

  function handleHoverOutOnHeader() {
    const timeOutID = setTimeout(() => {
      dispatch(setHeaderStateWithUrl(locationArr));
    }, 2000);
    dispatch(setTimeoutID(timeOutID));
  }

  function clearTimeOut() {
    dispatch(clearTimeout());
  }
  const getUnReadPushNotifications = () => {
    return homeAPI_apirequest({
      url: `${endpoint.unReadPushNotifications.addr}`,
      method: "post",
      headers: {
        "access-token": LoginDetails.token,
      },
      data: {
        courseId: courseID.toString(),
        variantId: variantID?.toString(),
      },
    });
  };

  useQuery([endpoint.unReadPushNotifications.key], getUnReadPushNotifications, {
    onSuccess: (res) => {
      setNotificationCount(res?.data?.data[0]?.number_of_pushNotification);
    },
  });
  const currentDomain = window.location.hostname;

  return (
    <div onMouseLeave={handleHoverOutOnHeader} onMouseEnter={clearTimeOut}>
      <div
        className={`w-full bg-secondary-100  sticky z-10
          ${
            showSubheader || showSecondSubheader
              ? "border-b-[1px] border-[#E5E5E5]"
              : "shadow-outer"
          }
        `}
      >
        <header className="h-20 w-[95%] mx-auto  flex flex-row justify-between items-center ">
          {/* Left Side */}
          <img
            className="h-16 cursor-pointer"
            style={{
              width: currentDomain === DOMAINS.MANYA_GROUP ? "auto" : "64px",
            }}
            src={
              currentDomain === DOMAINS.MANYA_GROUP
                ? HEADER_LOGOS.MANYA_GROUP_LOGO
                : HEADER_LOGOS.LOGO
            }
            alt={
              currentDomain === DOMAINS.MANYA_GROUP
                ? "Manya Group Logo"
                : "My IMS Logo"
            }
            onClick={() => history.push("/home")}
            loading="lazy"
          />

          {/* Middle */}
          <div className="flex flex-1 ">
            {currentHeaderData.map((option, index) => (
              <div
                key={index}
                onMouseEnter={() => handleHoverOnHeader(option)}
                onClick={() => handleClick(option)}
                className={` ml-12 text-lg font-ibm capitalize cursor-pointer ${
                  option.name?.toLowerCase() === currentHeaderTab?.toLowerCase()
                    ? " text-primary-200 font-medium"
                    : "font-normal"
                } `}
              >
                {option.name}
              </div>
            ))}
          </div>

          {/* Right Side */}
          <div className="flex flex-row items-center ">
            <Badge
              badgeContent={notificationCount}
              color="primary"
              overlap="circular"
              max={9}
            >
              <Link to={`${"/home/notification"}`}>
                <img
                  className="cursor-pointer"
                  src={notification}
                  alt="Notification Icon"
                  loading="lazy"
                />
              </Link>
            </Badge>
            <ProfileDropDown
              item={dropdownData}
              menuIcon={getStudentData?.profileImage}
            />
          </div>
        </header>
      </div>

      <SubheaderContainer />
    </div>
  );
};

export default Header;
