import React from "react";

import ManyaGroupFooter from "./ManyaGroupFooter";
import MyIMSFooter from "./MyIMSFooter";
import { DOMAINS } from "../../../../DomainConfig";

const Footer = () => {
  const currentDomain = window.location.hostname;

  if (currentDomain === DOMAINS.MANYA_GROUP) {
    return <ManyaGroupFooter />;
  }
  return <MyIMSFooter />;
};

export default Footer;
